.ant-drawer {
    .ant-drawer-content-wrapper {
        .ant-drawer-content.drawer-menu {
            .ant-drawer-wrapper-body {
                .ant-drawer-body {
                    padding: 0;
                }
            }
        }
    }
}